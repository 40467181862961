import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ReportService } from 'src/app/services/reports/report.service';
import { StorageService } from 'src/app/services/storage/storage.service';
import { UtilsService } from 'src/app/services/utils/utils.service';
import { differenceInCalendarDays, setHours } from 'date-fns';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-campaignid-report',
  templateUrl: './campaignid-report.component.html',
  styleUrls: ['./campaignid-report.component.scss']
})
export class CampaignidReportComponent implements OnInit {

  listOfColumn = [
    {
      title: 'Summary Date',
      compare: (a: any, b: any) => a.summaryDate.localeCompare(b.summaryDate),

      priority: false
    },
    {
      title: 'Campaign Name',
      compare: (a: any, b: any) => a.totalRequest.localeCompare(b.totalRequest),
      priority: false
    },
    {
      title: 'Total Request',
      compare: (a: any, b: any) => a.totalRequest - b.totalRequest,
      priority: false
    },
    {
      title: 'Total Rejected',
      compare: (a: any, b: any) => a.totalRejected - b.totalRejected,
      priority: false
    },
    {
      title: 'Total Submit',
      compare: (a: any, b: any) => a.totalSubmit - b.totalSubmit,
      priority: false
    },
    {
      title: 'Total Delivered',
      compare: (a: any, b: any) => a.totalDelivered - b.totalDelivered,
      priority: false
    }, {
      title: 'Total Failed',
      compare: (a: any, b: any) => a.totalFailed - b.totalFailed,
      priority: 5
    }, {
      title: 'Total Awaited',
      compare: (a: any, b: any) => a.totalAwaited - b.totalAwaited,
      priority: false
    }, 
    // {
    //   title: 'Action',
    //   compare: null,
    //   priority: false
    // },
    {
      title: 'Action',
      compare: null,
      priority: false
    }
  ];

  listOfData: any;
  downloadLink: any;
  downloadLink2: any;
  validateForm!: FormGroup;
  ranges = { Today: [this.utilsService.getToday(), this.utilsService.getToday()] };
  today = this.utilsService.getToday();
  isVisible = false;

  clickerLabel = ['totalDelivered', 'totalClicks'];
  deliveryLabel = ['totalDelivered', 'totalFailed', 'totalAwaited'];

  clickerChart: any;
  deliveryChart: any;
  chartData:any;
  rowData: any;
  constructor(private route: ActivatedRoute,
    private storageService: StorageService,
   
    private utilsService: UtilsService,
    private fb: FormBuilder,
    private http: HttpClient,
    private reportService: ReportService) { }

  disabledDate = (current: Date): boolean =>
    // Can not select days before today and today
    differenceInCalendarDays(current, this.today) > 0;

  ngOnInit(): void {
    let d=new Date;
    this.validateForm = this.fb.group({
      rangePicker: [[d,d]],
    });

    // this.getCampaignIdReport();
  }

  submitForm(): void {
    let form = this.validateForm.value;
    if (form.rangePicker) {
      this.getCampaignIdReport(form.rangePicker[0], form.rangePicker[1])
    }
  }

  getCampaignIdReport(from: any = this.today, to: any = this.today) {
    let params = {
      "loggedInUserName": this.storageService.username,
      "fromDate": this.utilsService.formatDate(from),
      "toDate": this.utilsService.formatDate(to),
      pageNumber: 1
    }

    this.reportService.getCampaignIdReport(params).subscribe(data => {
      this.listOfData = data.data.grid;
      this.downloadLink = data.data.downloadReportLink ? data.data.downloadReportLink.match(/href='(.*?)'/)[1] : null;
    })
  }

  downloadFile() {
    this.reportService.downloadFile(this.downloadLink);
  }

  ngOnDestroy() {
  }


  

  viewAnalyticsReport(data: any) {
    let params = {
      "loggedInUserName": this.storageService.username,
      "campaignId": data.campaignId,
      "campaignName": data.campaignName
    }
    this.reportService.getAlalyticsReport(params).subscribe(data => {
      console.log(data)
      let obj = data.data.grid[0];
      this.isVisible = true;
      let clickerData: any = [];
      let deliveryData: any = [];
      this.chartData = obj;

      for (let key in this.clickerLabel) {
        clickerData.push(parseInt(obj[this.clickerLabel[key]]));
      }
      console.log(clickerData)
      for (let key in this.deliveryLabel) {
        deliveryData.push(parseInt(obj[this.deliveryLabel[key]]));
      }

      this.populatePieChart(this.clickerLabel, clickerData, 'clicker')
      this.populatePieChart(this.deliveryLabel, deliveryData, 'delivery')

     

    })
  }

  populatePieChart(labels: any, data: any, type: string) {
    if (type === 'clicker') {
      this.clickerChart = {
        series: data,
        chart: {
          type: 'pie',
        },
        labels: labels,
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: 'bottom'
            }
          }
        }]
      };
    } else {
      this.deliveryChart = {
        series: data,
        chart: {
          type: 'pie',
        },
        labels: labels,
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: 'bottom'
            }
          }
        }]
      };
    }
  }

  receiveRowData(data: any) {
    this.rowData = data;
    console.log("Received Row Data:", this.rowData);
    console.log("Additional message: Atulya testing campaign download report");
  
    const fromDate = this.validateForm.value.rangePicker?.[0] 
    ? this.utilsService.formatDate(this.validateForm.value.rangePicker[0]) 
    : this.utilsService.getToday();

const toDate = this.validateForm.value.rangePicker?.[1] 
    ? this.utilsService.formatDate(this.validateForm.value.rangePicker[1]) 
    : this.utilsService.getToday();

    const params = {
      loggedInUserName: this.storageService.username,
      campaignId: data.campaignId,
      campaignName: data.campaignName,
      fromDate: fromDate,
      toDate: toDate,
    };
  
    const url = environment.url;
    this.downloadLink2 =`${url}/reportService/downloadClickerAnalytics/?loggedInUserName=${params.loggedInUserName}&campaignName=${params.campaignName}&campaignId=${params.campaignId}&fromDate=${params.fromDate}&toDate=${params.toDate}`;
  
    // Call the campaigndownload method with the constructed download link
    this.reportService.campaigndownload(this.downloadLink2);
  }
  

  handleOk(): void {
    this.isVisible = false;
  }

  handleCancel(): void {
    this.isVisible = false;
  }

}